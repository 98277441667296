import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "../../components/Community/Layout"
import slugify from "slugify"
import { client } from "../../sanity"

import { Flex, Grid, Title } from "../../components/system"
import Profile from "../../components/Community/profile"

const Assigned = () => {
  const [people, setPeople] = useState([])
  const { user } = useAuth0()

  useEffect(() => {
    const query =
      '*[_type == "user" && assigned._ref in *[_type=="expert" && uuid==$uuid]._id ]{_id, name, avatar{asset->{url}}}'
    const params = { uuid: user.sub }

    client.fetch(query, params).then(people => {
      console.log(people)
      if (people[0] !== undefined) {
        setPeople(people)
      }
    })
  }, [])

  return (
    <Layout>
      <Flex flexDirection="column">
        <Title fontSize={[5, 6]} my={5} color="secondary" px={[4, 7]}>
          Once you have been assigned to a designer they will show up here.
        </Title>
        {people && (
          <Grid
            gridGap={4}
            gridTemplateColumns={["1fr", "1fr 1fr ", "1fr 1fr 1fr"]}
            mt={5}
            px={[3, 5]}
            width="100%"
          >
            {people.map((person, key) => (
              <Link
                key={key}
                to={`/community/people/${slugify(person.name, {
                  lower: true,
                })}`}
                state={{ id: person._id }}
                style={{ display: "contents" }}
              >
                <Profile data={person} sanity />
              </Link>
            ))}
          </Grid>
        )}
      </Flex>
    </Layout>
  )
}

export default Assigned
